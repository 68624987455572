import React from "react";
import play from "../assets/css/icon_video.svg"

const SpfElementAudio = (props) => {

    return (
        <div className={`spf-rce-element spf-element-audio`}>
            {props.data.title && <div class="figure-title"><h3>{props.data.title}</h3></div>}
            <div className="component-container">
                <div className="play-icon">
                    <img src={play} alt="Play"/>
                </div>
                <div className="placeholder-scrubber">
                    <div className="bar"></div>
                    <div className="timestamp">00:00 / XX:XX</div>
                </div>
            </div>
        </div>           
    )
}
export default SpfElementAudio