import React from "react";

const SpfElementTable = (props) => {

    const CustomTag = (props) => {
        switch (props.type) {
            case 'h1':
                return <h1 dangerouslySetInnerHTML={{__html: props.children}}/>
            case 'h2':
                return <h2 dangerouslySetInnerHTML={{__html: props.children}}/>
            case 'h3':
                return <h3 dangerouslySetInnerHTML={{__html: props.children}}/>
            case 'h4':
                return <h4 dangerouslySetInnerHTML={{__html: props.children}}/>
            case 'p':
                return <p dangerouslySetInnerHTML={{__html: props.children}} />
            case 'p-strong':
                return <p><strong>{props.children}</strong></p>
            case 'body':
                return <p>{props.children}</p>
            case 'ol':
                return <ol>{props.children.map((item,index) => {return (<li><p dangerouslySetInnerHTML={{__html: item.content}} /></li>)})}</ol>
            case 'ul':
                return <ul>{props.children.map((item,index) => {return (<li><p dangerouslySetInnerHTML={{__html: item.content}} /></li>)})}</ul>
            case 'external-link':
                return <p><a href="#" className="external" dangerouslySetInnerHTML={{__html: props.children}}/></p>
            case 'showhide':
                return <div className="spf-rce-showhide">
                        <details>
                            <summary>
                                <div className="text-container">
                                    <span className="show-text">{props.children.showText}</span>
                                    <span className="hide-text">{props.children.hideText}</span>
                                </div>
                                <span className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                                        <path d="M31.1663 11.3333L16.9997 25.5L2.83301 11.3333L5.34759 8.81875L16.9997 20.4708L28.6518 8.81875L31.1663 11.3333Z" fill="#5F6368"/>
                                    </svg>
                                </span>
                            </summary>
                            <div className="show-hide-body">
                                {props.children.content.map((item) => {
                                    return <p dangerouslySetInnerHTML={{__html: item.content}} />
                                })}
                            </div>
                        </details>
                        
                        
                    </div>
            default:
                return <p>Need to build {props.type}</p>

                // Need to add show hide, icon
        }
    }

    return (
        <div className={`spf-rce-element spf-element-table`}>
            {props.data.title && <div className="figure-title"><h3>{props.data.title}</h3></div>}
            <div className={`table-container ${props.modifier ? props.modifier : ''}`}>
                <table>
                    <caption className="body-md"><p>{props.data.caption}</p></caption>
                    {props.data.thead && <thead></thead>}
                    {props.data.tbody && <tbody>
                        {props.data.tbody.tr.map((row,index) => {
                            return (<tr key={"row"+index}>
                                {row.columns.map((column, index2) => {
                                    if (column.type === 'th') {
                                        return (<th>
                                            {column.content.map((item,index) => {
                                                    return (
                                                        <CustomTag type={item.type}>{item.content}</CustomTag>
                                                        
                                                    )
                                                })}
                                            </th>)
                                    } else {
                                        return (<td>
                                            {column.content.map((item,index) => {
                                                    return (
                                                        <CustomTag type={item.type}>{item.content}</CustomTag>
                                                        
                                                    )
                                                })}
                                            </td>)
                                    }
                                })}

                            </tr>)
                        })}
                        </tbody>}
                </table>
            
            </div>
            
        </div>           
    )
}
export default SpfElementTable