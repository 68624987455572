import PlayerNavigation from "./PlayerNavigation";
import PlayerContent from "./PlayerContent";
import { useEffect, useRef, useState } from "react";
import PlayerHeader from "./PlayerHeader";
import PlayerContentScroll from "./PlayerContentScroll";

///content
import screen1_1 from "../assets/data/beginningWordRec/screen1-1.json";
import screen1_2 from "../assets/data/beginningWordRec/screen1-2.json";
import screen1_3 from "../assets/data/beginningWordRec/screen1-3.json";
import screen2_1 from "../assets/data/beginningWordRec/screen2-1.json";
import screen2_2 from "../assets/data/beginningWordRec/screen2-2.json";
import screen2_3 from "../assets/data/beginningWordRec/screen2-3.json";
import screen2_4 from "../assets/data/beginningWordRec/screen2-4.json";
import screen2_5 from "../assets/data/beginningWordRec/screen2-5.json";
import screen3_1 from "../assets/data/beginningWordRec/screen3-1.json";
import screen3_2 from "../assets/data/beginningWordRec/screen3-2.json";
import screen3_3 from "../assets/data/beginningWordRec/screen3-3.json";
import screen3_4 from "../assets/data/beginningWordRec/screen3-4.json";
import screen3_5 from "../assets/data/beginningWordRec/screen3-5.json";
import screen3_6 from "../assets/data/beginningWordRec/screen3-6.json";
import screen3_7 from "../assets/data/beginningWordRec/screen3-7.json";
import screen3_8 from "../assets/data/beginningWordRec/screen3-8.json";
import screen3_9 from "../assets/data/beginningWordRec/screen3-9.json";
import screen3_10 from "../assets/data/beginningWordRec/screen3-10.json";
import screen3_11 from "../assets/data/beginningWordRec/screen3-11.json";
import screen3_12 from "../assets/data/beginningWordRec/screen3-12.json";
import screen3_13 from "../assets/data/beginningWordRec/screen3-13.json";
import screen3_14 from "../assets/data/beginningWordRec/screen3-14.json";
import screen4_1 from "../assets/data/beginningWordRec/screen4-1.json";
import screen4_2 from "../assets/data/beginningWordRec/screen4-2.json";
import screen4_3 from "../assets/data/beginningWordRec/screen4-3.json";
import screen5_1 from "../assets/data/beginningWordRec/screen5-1.json";
import screen5_2 from "../assets/data/beginningWordRec/screen5-2.json";
import screen5_3 from "../assets/data/beginningWordRec/screen5-3.json";
import screen5_4 from "../assets/data/beginningWordRec/screen5-4.json";
import screen5_5 from "../assets/data/beginningWordRec/screen5-5.json";
import screen5_6 from "../assets/data/beginningWordRec/screen5-6.json";
import screen5_7 from "../assets/data/beginningWordRec/screen5-7.json";

const mapping = [
    {
        id: 'screen1-1',
        data: screen1_1
    },
    {
        id: 'screen1-2',
        data: screen1_2
    },
    {
        id: 'screen1-3',
        data: screen1_3
    },
    {
        id: 'screen2-1',
        data: screen2_1
    },
    {
        id: 'screen2-2',
        data: screen2_2
    },
    {
        id: 'screen2-3',
        data: screen2_3
    },
    {
        id: 'screen2-4',
        data: screen2_4
    },
    {
        id: 'screen2-5',
        data: screen2_5
    },
    {
        id: 'screen3-1',
        data: screen3_1
    },
    {
        id: 'screen3-2',
        data: screen3_2
    },
    {
        id: 'screen3-3',
        data: screen3_3
    },
    {
        id: 'screen3-4',
        data: screen3_4
    },
    {
        id: 'screen3-5',
        data: screen3_5
    },
    {
        id: 'screen3-6',
        data: screen3_6
    },
    {
        id: 'screen3-7',
        data: screen3_7
    },
    {
        id: 'screen3-8',
        data: screen3_8
    },
    {
        id: 'screen3-9',
        data: screen3_9
    },
    {
        id: 'screen3-10',
        data: screen3_10
    },
    {
        id: 'screen3-11',
        data: screen3_11
    },
    {
        id: 'screen3-12',
        data: screen3_12
    },
    {
        id: 'screen3-13',
        data: screen3_13
    },
    {
        id: 'screen3-14',
        data: screen3_14
    },
    {
        id: 'screen4-1',
        data: screen4_1
    },
    {
        id: 'screen4-2',
        data: screen4_2
    },
    {
        id: 'screen4-3',
        data: screen4_3
    },
    {
        id: 'screen5-1',
        data: screen5_1
    },
    {
        id: 'screen5-2',
        data: screen5_2
    },
    {
        id: 'screen5-3',
        data: screen5_3
    },
    {
        id: 'screen5-4',
        data: screen5_4
    },
    {
        id: 'screen5-5',
        data: screen5_5
    },
    {
        id: 'screen5-6',
        data: screen5_6
    },
    {
        id: 'screen5-7',
        data: screen5_7
    }
]

const Player = (props) => {
    const [data, setData] = useState(screen1_1);
    const [answerSelected, setAnswerSelected] = useState(false);
    const [answerCorrect, setAnswerCorrect] = useState(false);
    const [navigationOpen, setNavigationOpen] = useState(false);
    const [mobileMenuOpen, setmobileMenuOpen] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState(false);
    const [modbilescoreOpen, setMobileScoreOpen] = useState(false);
    const [showHeader, setShowHeader] = useState(true);
    const [scrollTop, setScrollTop] = useState(0);
    let random = Math.random().toFixed(4);

    let scorePercentage = parseInt((props.data.overallscore / props.data.totalPoints) * 100);

    const selectedCardRef = useRef(null);
    const fbRef = useRef(null);
    const iFrameRef = useRef(null);

    useEffect(() => {
        //data={props.itemsList[props.currentItem]} )
        // console.log(props.itemsList[props.currentItem].data)
        // console.log(`${props.itemsList[props.currentItem].data}`)
        let dataId = props.itemsList[props.currentItem].data
        let pageData = mapping.filter(f => f.id === dataId)[0].data
        setData(pageData)
        window.scrollTo(0,0)

    },[props.currentItem])

    const selectAsnwer = (selAns) => {
        setSelectedAnswer(selAns);
        setAnswerSelected(true);
    }

    const answerEntered = () => {
        setAnswerSelected(true);

    }

    const checkAnswer = () => {        
        setAnswerCorrect(true);
        setTimeout(() => {
            fbRef.current.scrollIntoView(true);
        }, 200);
    }

    const toggleNavigation = () => {
        setNavigationOpen(!navigationOpen);
        if (!navigationOpen) {
            setShowHeader(true)
        }
    }
    const goToItemFromNav = () => {
        // toggleNavigation()
        props.gotoItem()
        setNavigationOpen(false);
    }

    const nextItem = () => {
        setAnswerSelected(false);
        setAnswerCorrect(false);
        setSelectedAnswer(false);
        props.nextItem();
    }
    const prevItem = () => {
        console.log("go to prev")
        // setAnswerSelected(false);
        // setAnswerCorrect(false);
        // setSelectedAnswer(false);
        props.prevItem(props.currentItem - 1);
    }

    const toggleMobileMenu = () => {
        setmobileMenuOpen(!mobileMenuOpen);
    }
    const toggleMobileScores = () => {
        setMobileScoreOpen(!modbilescoreOpen);
    }


    useEffect(() => {
        setTimeout(() => {
            if (selectedCardRef.current) {                
                document.getElementById('card-selected').scrollIntoView(true);
            }
        }, 300)
        localStorage.setItem('currentItem', props.currentItem);
    });
    // useEffect(() => {
    //     var prevScrollpos = window.pageYOffset;
        
    //     function onScroll() {
    //         var currentScrollPos = window.pageYOffset;
    //         // var st = window.scrollTop;
    //         if (prevScrollpos <= currentScrollPos ){
    //             // downscroll code
    //             // console.log("hide header")
    //             setShowHeader(false)
    //         } else {
    //             // upscroll code
    //             setShowHeader(true)
    //         } // else was horizontal scroll
    //         prevScrollpos = currentScrollPos; // For Mobile or negative scrolling

    //     }
      
    //       window.addEventListener("scroll", onScroll);
    //       return () => window.removeEventListener("scroll", onScroll);
    //     }, []);
   

    return(        
        <>
        <PlayerHeader toggleMobileMenu={toggleMobileMenu} mobileMenuOpen={mobileMenuOpen} 
            show={showHeader}
            data={props.data} closePlayer={props.closePlayer}/>
            
            <main className={`player-main ${showHeader ? "show-header" : "hide-header"}`}>
                <PlayerNavigation 
                    data={props.data} 
                    itemsList={props.itemsList} 
                    currentItem={props.currentItem} 
                    gotoItem={props.gotoItem}
                    itemsCompleted={props.itemsCompleted}
                    totalItems={props.data.totalItems}
                    toggleNavigation={toggleNavigation}
                    navigationOpen={navigationOpen}
                    mobileMenuOpen={mobileMenuOpen}
                    toggleMobileMenu={toggleMobileMenu}
                    ref={selectedCardRef}
                />
                {props.scroll ? 
                <PlayerContentScroll 
                    data={props.itemsList}
                    items={props.itemsList} 
                    currentItem={props.currentItem} 
                    nextItem={nextItem} 
                    prevItem={prevItem}
                    answerSelected={answerSelected}
                    selectAsnwer={selectAsnwer}
                    answerCorrect={answerCorrect}
                    checkAnswer={checkAnswer}
                    selectedAnswer={selectedAnswer}
                    // navigationOpen={navigationOpen}
                    answerEntered={answerEntered}
                    currentStep={props.currentStep}
                    totalSteps={props.totalSteps}
                    totalItems={props.data.totalItems}
                    ref={{ref1:fbRef,iFrameRef:iFrameRef}}
                    demo={props.data.demo}
                    id={random}
                    gotoItem={props.gotoItem}
                /> : 
                <PlayerContent 
                    data={data} 
                    //data={props.itemsList[props.currentItem]} 
                    currentItem={props.currentItem} 
                    nextItem={nextItem} 
                    prevItem={prevItem}
                    answerSelected={answerSelected}
                    selectAsnwer={selectAsnwer}
                    answerCorrect={answerCorrect}
                    checkAnswer={checkAnswer}
                    selectedAnswer={selectedAnswer}
                    // navigationOpen={navigationOpen}
                    answerEntered={answerEntered}
                    currentStep={props.currentStep}
                    totalSteps={props.totalSteps}
                    totalItems={props.data.totalItems}
                    ref={{ref1:fbRef,iFrameRef:iFrameRef}}
                    demo={props.data.demo}
                    id={random}
                    
                /> }
            </main>
        </>  
    )
}

export default Player