
import PlayerFooter from "./PlayerFooter";
import Accordion from '../shared/Accordion';
import SplitPane from 'react-split-pane';
// import './assets/css/bronte_theme2.css';
import { forwardRef, useEffect, useRef } from "react";
import NarrativeContent from "./NarrativeContent";
import influenza from '../assets/data/influenzaContent.json';
import SpfScreen from "../content/SpfScreen";
import SpfSection from "../content/SpfSection";
import SpfRow from "../content/SpfRow";
import SpfColumn from "../content/SpfColumn";
import SpfElementText from "../content/SpfElementText";
import SpfElementImage from "../content/SpfElementImage";
import SpfCarousel from "../content/SpfCarousel";
import SpfFlipcard from "../content/SpfFlipcard";
import SpfElementIframe from "../content/SpfElementIframe";
import SpfElementAudio from "../content/SpfElementAudio";
import SpfElementTable from "../content/SpfElementTable";

const PlayerContent = forwardRef((props, ref) => {
    const {iFrameRef, ref1} = ref;
    const assignment = useRef();

    useEffect(() => {
        let currentFrame = assignment.current;

        const cb1 = function(entries){
            entries.forEach(entry => {
                if(entry.isIntersecting){
                    entry.target.classList.replace('trigger-slide-in-right','slide-in-right');
                }else{
                    // entry.target.classList.replace('slide-in-right', 'trigger-slide-in-right');
                }
            });
        }
        const cb2 = function(entries){
            entries.forEach(entry => {
                if(entry.isIntersecting){
                    entry.target.classList.replace('trigger-slide-in-left','slide-in-left');
                }else{
                    // entry.target.classList.replace('slide-in-left', 'trigger-slide-in-left');
                }
            });
        }
        const cb3 = function(entries){
            entries.forEach(entry => {
                if(entry.isIntersecting){
                    entry.target.classList.replace('trigger-slide-in-bottom','slide-in-bottom');
                }else{
                    // entry.target.classList.replace('slide-in-bottom', 'trigger-slide-in-bottom');
                }
            });
        }
        const io1 = new IntersectionObserver(cb1, {rootMargin:'-50% 0% -50% 0%'});
        const io2 = new IntersectionObserver(cb2, {rootMargin:'-50% 0% -50% 0%'});
        const io3 = new IntersectionObserver(cb3, {rootMargin:'-50% 0% -50% 0%'});

        if (currentFrame) {
            const slideInRights = currentFrame.querySelectorAll('.trigger-slide-in-right'); 
            const slideInLefts = currentFrame.querySelectorAll('.trigger-slide-in-left'); 
            const slideInBottoms = currentFrame.querySelectorAll('.trigger-slide-in-bottom'); 

           
            
            slideInRights.forEach(item => {
                io1.observe(item);
            })
            slideInLefts.forEach(item => {
                io2.observe(item)
            })
            slideInBottoms.forEach(item => {
                io3.observe(item)
            })
        }

        // Cleanup function
        return () => {
            if (currentFrame) {
                io1.unobserve(currentFrame);
                io2.unobserve(currentFrame);
                io3.unobserve(currentFrame)
            }
        };
        
    },[props.data])

    
    let random = Math.random().toFixed(4);
    
    // console.log(props.totalItems)
    
    return(
        <article className="assignment-content" ref={assignment}>
            <section className="item-content" id="assignmentContent" lang="en">
                {props.data.screens?.map((screen, i) => {
                   return (
                    <SpfScreen modifier={screen.modifier} key={screen.id} backgroundImage={screen.backgroundImage} backgroundColor={screen.backgroundColor}>
                        {screen.sections.map((section,index) => {
                            return (
                                <SpfSection modifier={section.modifier}>
                                    {section.rows?.map((row,in2) => {
                                        return (
                                            <SpfRow>
                                                {row.columns?.map((column,in3) => {
                                                    return (
                                                        <SpfColumn size={column.size}>
                                                            {column.content.map((content,in4) => {
                                                                // console.log(content)
                                                                if (content.type === 'spacer') {
                                                                    return <div className='spf-rce-spacer'/>
                                                                }
                                                                else if (content.type === 'text') {
                                                                    return (
                                                                        <SpfElementText data={content.content} modifier={content.modifier}/>
                                                                    )
                                                                } else if (content.type === 'image') {
                                                                    return (
                                                                        <SpfElementImage data={content.content} modifier={content.modifier}/>
                                                                    )
                                                                } else if (content.type === 'carousel') {
                                                                    return (
                                                                        <SpfCarousel data={content.content} />
                                                                    )
                                                                } else if (content.type === 'flipcards') {
                                                                    return (
                                                                        <SpfFlipcard data={content.content} cardHeight={content.cardHeight} />
                                                                    )
                                                                } else if (content.type === 'iframe') {
                                                                    return (
                                                                        <SpfElementIframe data={content.content} modifier={content.modifier} height={content.height} />
                                                                    )
                                                                    
                                                                } else if (content.type === 'audio') { 
                                                                    return (
                                                                        <SpfElementAudio data={content.content} modifier={content.modifier} />
                                                                    )

                                                                }
                                                                else if (content.type === 'table') {
                                                                    return (
                                                                        <SpfElementTable data={content.content} modifier={content.modifier}/>
                                                                    )
                                                                } 
                                                                else {
                                                                    return (<div className="spf-rce-element"><p>Need content type: {content.type}</p></div>)
                                                                }
                                                            })}
                                                        </SpfColumn>
                                                    )
                                                })}
                                            </SpfRow>
                                        )
                                    })}
                                </SpfSection>
                            )
                        })}
                    </SpfScreen>
                   )
                })}
                    
            </section>

        
            <PlayerFooter 
                currentItem={props.currentItem} 
                action={props.data.action} 
                nextItem={props.nextItem}
                prevItem={props.prevItem}
                answerSelected={props.answerSelected}
                checkAnswer={props.checkAnswer}
                answerCorrect={props.answerCorrect}
                itemType={props.data.type}
                // navigationOpen={props.navigationOpen}
                subcat={props.data.subcat}
                disablePrev={props.currentItem === 0 || props.demo}
                disableNext={props.totalItems === 1 || props.demo}
            /> 
        </article>
    )
})

export default PlayerContent